<template>
  <div
    class="full-height"
  >
    <v-app-bar
      v-if="!$vuetify.breakpoint.mobile"
      app
      dense
      dark
      flat
      color="secondary"
      style="margin-right: -16px;"
      class="no-print"
    >
      <v-app-bar-nav-icon
        class="ml-n2 mr-4"
        @click="toggleMenu"
      >
        <div
          class="d-flex flex-column justify-center align-center"
        >
          <div
            class="d-flex"
          >
            <v-icon
              v-if="!drawerClosed"
              small
            >
              menu_open
            </v-icon>

            <v-icon
              v-if="drawerClosed"
              small
            >
              menu
            </v-icon>
          </div>

          <span
            v-translate
            translate-context="MBaseNavigation menu toggle"
            class="caption-xxs mb-2"
          >
            Menu
          </span>
        </div>
      </v-app-bar-nav-icon>

      <v-spacer />

      
      <v-menu
        open-on-hover
        offset-y
        :close-on-content-click="false"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn 
            v-bind="attrs"
            v-on="on"
            color="error"
            class="mr-4"
            target="_blank"
            href="https://beta.mestamaster.com">
            <span small>
              GO TO NEW MESTAMASTER
            </span>
          </v-btn>
        </template>
        <v-card>
          <v-card-title>
            You are using an old version of mestamaster
          </v-card-title>
          <v-card-subtitle>
            <strong> The new version will soon be the default, but you can still access this version at <a href="https://legacy.mestamaster.com">legacy.mestamaster.com</a> until May 2025 </strong>
          </v-card-subtitle>

          <v-card-text
            class="pb-5"
          >
                You can already use the new version in <a href="https://beta.mestamaster.com">beta.mestamaster.com</a><br>
                We have already collected extensive feedback from users to ensure the transition brings only advantages, but if anything critical for your workflow 
                is missing in the new version, please let us know at <a href="mailto:info@mestamaster.fi">info@mestamaster.fi </a><br>
                This version will no loger recieve any updates or bug fixes.
            </v-card-text>
        </v-card>
      </v-menu>

      <m-notification-fab
        v-if="!$vuetify.breakpoint.mobile"
        icon
        overlap
        class="mr-4"
      />
    </v-app-bar>

    <m-navigation-drawer
      v-if="!$vuetify.breakpoint.mobile"
      :project-id="projectId"
      :projects="projects"
      :nav-items="projectNavItems"
      :drawer="drawer"
      :mini-variant="miniVariant"
    />

    <router-view />

    <m-notification-fab
      v-if="$vuetify.breakpoint.mobile"
      fab
      color="secondary"
      bordered
      fixed
      left
      style="bottom: 72px;"
    />
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import { mapWaitingActions } from 'vue-wait';
  import MBreadcrumbs from '@/components/MBreadcrumbs';
  import MNavigationDrawer from '@/components/MNavigationDrawer';
  import MNotificationFab from '@/components/MNotificationFab';

  export default {
    components: { MNotificationFab, MNavigationDrawer, MBreadcrumbs },

    props: {
      projectId: {
        type: [Number, String],
        default: null,
      },
    },

    data: () => ({
      drawer: null,
      miniVariant: null,
    }),

    computed: {
      ...mapGetters({
        projects: 'projects/projects',
        projectNavItems: 'nav/projectNavItems'
      }),

      renderCollapseExpand() {
        if (this.miniVariant) {
          return this.$pgettext('Base navigation toggle', 'Expand');
        }

        return this.$pgettext('Base navigation toggle', 'Minimize');
      },

      renderHideOpen() {
        if (this.drawer === null || this.drawer === true) {
          return this.$pgettext('Base navigation toggle', 'Hide');
        }

        return this.$pgettext('Base navigation toggle', 'Open');
      },

      drawerOpened() {
        return (this.drawer === null || this.drawer) && !this.miniVariant;
      },

      drawerMiniVariant() {
        return this.drawer && this.miniVariant;
      },

      drawerClosed() {
        return !this.drawerOpened && !this.drawerMiniVariant;
      },
    },

    created() {
      this.loadProjects();
      this.loadCompanies();
    },

    methods: {
      ...mapWaitingActions('projects', {
        loadProjects: 'loading projects',
      }),

      ...mapWaitingActions('companies', {
        loadCompanies: 'loading companies',
      }),

      toggleMinivariant() {
        this.miniVariant = !this.miniVariant;

        this.$mixpanel.trackEvent('Toggle Minivariant', {
          action: this.miniVariant ? 'Mini' : 'Wide',
        });
      },

      toggleSidebarNavigation() {
        this.drawer = this.drawer === null ? false : !this.drawer;

        this.$mixpanel.trackEvent('Toggle Sidebar Navigation', {
          action: this.drawer ? 'Open' : 'Close',
        });
      },

      toggleMenu() {
        if (this.drawerOpened) {
          this.drawer = true;
          this.miniVariant = true;
          return;
        }

        if (this.drawerMiniVariant) {
          this.drawer = false;
          return;
        }

        this.drawer = true;
        this.miniVariant = false;
      },
    },
  };
</script>
